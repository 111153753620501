import * as React from 'react';
import { ILxsWhatsNewData } from './lxs-whats-new.data';
import { ILxsWhatsNewProps, modalSize } from './lxs-whats-new.props.autogenerated';
import { ModalProvider } from '../../components/modal';
import * as MsDyn365 from '@msdyn365-commerce/core';
import { isValidDate } from '../../utilities/validate-date';
import { convertStringToDate } from '../../utilities/convert-string-date-to-date-object';
import { SHA256, enc } from 'crypto-js';
export interface ILxsWhatsNewViewProps extends ILxsWhatsNewProps<ILxsWhatsNewData> {
    text: string;
    modalSize: modalSize;
    currentDate: Date;
    contentHash: string;
}

/**
 *
 * LxsWhatsNew component
 * @extends {React.PureComponent<ILxsWhatsNewProps<ILxsWhatsNewData>>}
 */
class LxsWhatsNew extends React.PureComponent<ILxsWhatsNewProps<ILxsWhatsNewData>> {
    public render(): JSX.Element | null {
        const currentDate: Date = new Date();
        let contentHash: String = '';
        let showModal: boolean = false;
        const { startDate, endDate, content, modalSize } = this.props.config;

        const displayText = content && (
            <MsDyn365.RichTextComponent
                text={content}
                editProps={{ onEdit: this.handleTextChange, requestContext: this.props.context.request }}
            />
        );

        if (startDate && endDate && isValidDate(startDate) && isValidDate(endDate) && content) {
            const startDateConverted = convertStringToDate(startDate);
            const endDateConverted = convertStringToDate(endDate);
            const contentString = content.toString();
            contentHash = SHA256(contentString).toString(enc.Hex);

            const lastContentHash: string | undefined = this.props.context.request.cookies.get<string>(
                this.props.resources.lxsWhatsNewCookieHashName
            ).value;

            if (lastContentHash !== contentHash) {
                showModal =
                    (startDateConverted <= currentDate && endDateConverted >= currentDate) ||
                    (lastContentHash !== undefined && lastContentHash !== contentHash);
                this.props.context.request.cookies.setConsentCookie();
            }
        }

        const textBlockViewProps = {
            ...this.props,
            text: displayText,
            modalSize: modalSize,
            currentDate: currentDate,
            contentHash: contentHash
        };

        return (
            <>
                {showModal && (
                    <ModalProvider props={this.props}>{this.props.renderView(textBlockViewProps) as React.ReactElement}</ModalProvider>
                )}
            </>
        );
    }

    public handleTextChange = (event: MsDyn365.ContentEditableEvent) => (this.props.config.content = event.target.value);
}

export default LxsWhatsNew;

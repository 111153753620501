import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { IOrderDetailsResources } from '../order-details.props.autogenerated';
import { IOrderDetailResponse, IOrderDetailScheduleLine } from '../../../actions/get-order-details.action';
import { IOrderDetailsViewProps } from '../order-details';
import {
    formatDate,
    formatCurrency,
    formatNumber,
    formatTime,
    mapItemStatusCodeToText,
    formatStatusCodeForTooltip
} from '../../../utilities';
import { NO_DATA_FALLBACK } from '../../../configuration';
import Tooltip from '../../../components/tooltip';

import { getReorderPageUrl } from './get-reorder-url';
import { useModal } from '../../../components/modal';

function ModalContent({ context, content, resources }: { context: ICoreContext; content: any; resources: IOrderDetailsResources }) {
    return (
        <div className='ms-order-details__delivery-information'>
            <div className='ms-order-details__delivery-information-infobox mb-5'>
                <table className='table table-borderless table-sm mb-0'>
                    <tbody>
                        <tr>
                            <td>
                                <strong>{resources.orderDetailsModalDeliveryNumber}</strong>
                            </td>
                            <td>{content.DeliveryNumber ? content.DeliveryNumber : NO_DATA_FALLBACK}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{resources.orderDetailsModalDeliveryDate}</strong>
                            </td>
                            <td>{content.DeliveryDate ? formatDate(context, new Date(content.DeliveryDate)) : NO_DATA_FALLBACK}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className='mb-3'>{resources.orderDetailsModalPreCarriageHeading}</h3>
            <table className='table table-borderless table-sm mb-5'>
                <tbody>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalPreCarriageLastEventLocation}</strong>
                        </td>
                        <td>{content.PreCarriageLastEventLocation ? content.PreCarriageLastEventLocation : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalPreCarriageDepartureDate}</strong>
                        </td>
                        <td>
                            {content.PreCarriageDepartureDate
                                ? formatDate(context, new Date(content.PreCarriageDepartureDate))
                                : NO_DATA_FALLBACK}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalPreCarriageArrivalDate}</strong>
                        </td>
                        <td>
                            {content.PreCarriageArrivalDate
                                ? formatDate(context, new Date(content.PreCarriageArrivalDate))
                                : NO_DATA_FALLBACK}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalConfirmedPickupDateLbn}</strong>
                        </td>
                        <td>
                            {content.ConfirmedPickupDateLBN
                                ? formatDate(context, new Date(content.ConfirmedPickupDateLBN))
                                : NO_DATA_FALLBACK}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalConfirmedPickupTimeLbn}</strong>
                        </td>
                        <td>
                            {content.ConfirmedPickupDateLBN && content.ConfirmedPickupTimeLBN
                                ? formatTime(context, new Date(`${content.ConfirmedPickupDateLBN} ${content.ConfirmedPickupTimeLBN}`))
                                : NO_DATA_FALLBACK}
                        </td>
                    </tr>
                </tbody>
            </table>
            <h3 className='mb-3'>{resources.orderDetailsModalMainCarriageHeading}</h3>
            <table className='table table-borderless table-sm'>
                <tbody>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalActualArrivalDate}</strong>
                        </td>
                        <td>{content.ActualArrivalDate ? formatDate(context, new Date(content.ActualArrivalDate)) : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalGoodsMovementDate}</strong>
                        </td>
                        <td>{content.GoodsMovementDate ? formatDate(context, new Date(content.GoodsMovementDate)) : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalActualEventDate}</strong>
                        </td>
                        <td>{content.ActualEventDate ? formatDate(context, new Date(content.ActualEventDate)) : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalActualEvent}</strong>
                        </td>
                        <td>{content.ActualEvent ? content.ActualEvent : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalCarrierName}</strong>
                        </td>
                        <td>{content.CarrierName ? content.CarrierName : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalContainer}</strong>
                        </td>
                        <td>{content.Container ? content.Container : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalContainerType}</strong>
                        </td>
                        <td>{content.ContainerType ? content.ContainerType : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalShipFlightNumber}</strong>
                        </td>
                        <td>{content.ShipFlightNumber ? content.ShipFlightNumber : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalDepartureDate}</strong>
                        </td>
                        <td>{content.DepartureDate ? formatDate(context, new Date(content.DepartureDate)) : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalArrivalDate}</strong>
                        </td>
                        <td>{content.ArrivalDate ? formatDate(context, new Date(content.ArrivalDate)) : NO_DATA_FALLBACK}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalEstimatedTimeOfSailing}</strong>
                        </td>
                        <td>
                            {content.EstimatedTimeOfSailing
                                ? formatDate(context, new Date(content.EstimatedTimeOfSailing))
                                : NO_DATA_FALLBACK}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{resources.orderDetailsModalBLnumber}</strong>
                        </td>
                        <td>{content.BLnumber ? content.BLnumber : NO_DATA_FALLBACK}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default function orderItems({
    orderDetails,
    resources,
    context
}: {
    context: ICoreContext;
    orderDetails: IOrderDetailResponse;
    resources: IOrderDetailsResources;
    props: IOrderDetailsViewProps;
}) {
    const { setOpen } = useModal();

    const showEndCustomer: boolean = orderDetails.OrderDetail.Endcustomers;

    return (
        <>
            <h3 className='ms-order-details__section-heading'>{resources.orderDetailsItemsSectionHeading}</h3>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>{resources.orderDetailsItemsTableProductName}</th>
                            <th>{resources.orderDetailsItemsTableProductNumber}</th>
                            <th>
                                <Tooltip label={resources.orderDetailsItemsTableStatus} content={formatStatusCodeForTooltip(resources)} />
                            </th>
                            <th>{resources.orderDetailsItemsTableReqDeliveryDate}</th>
                            <th>{resources.orderDetailsItemsTableConfDeliveryDate}</th>
                            <th>{resources.orderDetailsItemsTableReqQuantity}</th>
                            <th>{resources.orderDetailsItemsTableConfQuantity}</th>
                            <th>{resources.orderDetailsItemsTableDelQuantity}</th>
                            {showEndCustomer && <th>{resources.orderDetailsItemsTableEndcustomer}</th>}
                            <th>{resources.orderDetailsItemsTableDelivery}</th>
                            <th>{resources.orderDetailsItemsTableInvoiceNumber}</th>
                            <th>
                                <Tooltip label={resources.orderDetailsItemsTableNetValue} content={resources.globalTableNetValueToolTip} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetails?.OrderDetail.OrderDetailLines?.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ProductName}</td>
                                <td>{item.ProductNumber}</td>
                                <td>{mapItemStatusCodeToText(item.Status, resources)}</td>
                                <td>{item.RequestedDate && formatDate(context, new Date(item.RequestedDate))}</td>
                                <td>
                                    {item.ScheduleLines.map((sLine: IOrderDetailScheduleLine, index: number) => {
                                        return (
                                            <span className='ms-order-details__block-item' key={index}>
                                                {sLine.ConfirmedDate && formatDate(context, new Date(sLine.ConfirmedDate))}
                                            </span>
                                        );
                                    })}
                                </td>
                                <td className='ms-order-details__block-item'>
                                    {`${formatNumber(context, Number(item.RequestedQty))} ${item.RequestedUnit}`}
                                </td>
                                <td>
                                    {item.ScheduleLines.map((sLine: IOrderDetailScheduleLine, index: number) => {
                                        return (
                                            <span className='ms-order-details__block-item' key={index}>
                                                {`${formatNumber(context, Number(sLine.ConfirmedQty))} ${sLine.ConfirmedUnit}`}
                                            </span>
                                        );
                                    })}
                                </td>
                                <td>
                                    {item.ScheduleLines.map((sLine: IOrderDetailScheduleLine, index: number) => {
                                        return (
                                            <span className='ms-order-details__block-item' key={index}>
                                                {`${formatNumber(context, Number(item.DeliveredQty))} ${sLine.ConfirmedUnit}`}
                                            </span>
                                        );
                                    })}
                                </td>
                                {showEndCustomer && <td>{item.Endcustomer}</td>}
                                <td>
                                    {item.DeliveryNumber.length !== 0 ? (
                                        <a
                                            onClick={() =>
                                                setOpen({
                                                    content: <ModalContent context={context} content={item} resources={resources} />,
                                                    header: resources.orderDetailsModalDeliveryInformationHeading,
                                                    size: 'lg'
                                                })
                                            }
                                            title={resources.orderDetailsShowDeliveryInformationTitle}
                                            className='ms-order-details__block-item ms-order-details__link-pointer ms-order-details__delivery-number'
                                        >
                                            {item.DeliveryNumber}
                                        </a>
                                    ) : (
                                        <span className='ms-order-details__block-item'>-</span>
                                    )}
                                </td>
                                <td>
                                    <span className='ms-order-details__block-item'>
                                        {item.InvoiceNumber.length !== 0 ? item.InvoiceNumber : NO_DATA_FALLBACK}
                                    </span>
                                </td>
                                <td className='ms-order-details__block-item'>
                                    {item.Currency ? formatCurrency(context, item.Currency, Number(item.NetValue)) : NO_DATA_FALLBACK}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {orderDetails.OrderDetail.ReorderAllowed && (
                    <a
                        className='msc-cta__primary ms-order-details__reorder-button'
                        onClick={() =>
                            setOpen({
                                content: (
                                    <div>
                                        <p className='mb-2'>{resources.orderDetailsReorderConfirmationHint}</p>
                                        <a
                                            className='msc-cta__primary'
                                            href={getReorderPageUrl(context.actionContext, orderDetails.OrderDetail.OrderNumber ?? '')}
                                        >
                                            {resources.orderDetailsReorderConfirmation}
                                        </a>
                                    </div>
                                ),
                                size: 'sm'
                            })
                        }
                    >
                        {resources.orderDetailsReorderLabel}
                    </a>
                )}
            </div>
        </>
    );
}

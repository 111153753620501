/**
 * Convert given date string to Date object.
 * @param dateString - date to convert.
 * @returns String value.
 */

export const convertStringToDate = (dateString: string): Date => {
    const [Day, Month, Year] = dateString.split('.');
    const dateConverted = new Date(Number(Year), Number(Month) - 1, Number(Day));
    return dateConverted;
};

/**
 * Check if a given date string is valid.
 * @param dateString - date to check.
 * @returns String value.
 */

export const isValidDate = (dateString: string): boolean => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;
    if (!regex.test(dateString)) {
        return false;
    }
    const parts = dateString.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Check the validity of the date
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};
